<template>
  <lay-layout class="example">
    <lay-layout>
      <lay-header>
        <h2>欢迎来到leon分享站～</h2>
      </lay-header>
      <lay-body>
        <lay-tab v-model="current1">
          <lay-tab-item title="python" id="1">
            <div style="padding:20px">内容为python使用心得</div>
          </lay-tab-item>
          <lay-tab-item title="java" id="2">
            <div style="padding:20px">内容为java使用心得</div>
          </lay-tab-item>
        </lay-tab>
      </lay-body>
    </lay-layout>
    <lay-side>
      <h1 class="title">公告栏目</h1>
      <a>暂无～</a>
    </lay-side>
  </lay-layout>
  <p>版权所有，Leon分享站保留一切权利！<a href="https://beian.miit.gov.cn/" target="_blank">© {{ (new Date()).getFullYear() }} 粤ICP备2023089465号</a></p>
</template>
<script>
import { ref } from 'vue'
export default {
  name: 'myIndex',
  setup() {
    const current1 = ref("1")
    return {
      current1
    }
  }
}
</script>
<style>
.example {
  height: 800px;
}

.example .layui-footer,
.example .layui-header {
  color: #000;
  line-height: 60px;
  text-align: center;
  background: #b8d49f;
}

.example .layui-side {
  background: #b6f4c7;
  align-items: center;
  color: #000;
}

.title {
  margin-top: 20px;
}

.example .layui-body {
  background: #e8f9ed;
  align-items: center;
  color: #000;
}</style>
